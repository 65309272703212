import { Card, Link } from "@mui/material";
import styled from "styled-components";


const c1 = "#72C8CC"

export const CardCompany = styled(Card)`
    min-height: 106px;
    box-shadow: 0px 6px 20px #EFF1F7 !important;
    border-radius: 8px !important;
    border-color: #EFF1F7 !important;
`
export const TitleCompany = styled.h3`
    font-family: 'Oxygen';
    font-weight: 400;
    font-size: 24px;
    color: #0039C5;
    margin-block-start: 25px;
    margin-block-end: 9px;
`

export const CardCounter = styled(Card)`
    width: 198px;
    height: 180px;
    box-shadow: 0px 6px 20px #EFF1F7 !important;
    border-radius: 8px !important;
    border-color: #EFF1F7 !important;
    padding: 16px;
`
export const LogoCardCounter = styled.div`
    height: 48px;
    display: flex;
    align-items: center;
    font-feature-settings: 'liga' off;
`
export const ContainerLogo = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    background: #537DE4;
    box-shadow: 0px 6px 20px #EFF1F7;
    border-radius: 12px;
`
export const ContentCardCounter = styled.div`
    height: 54px;
    font-family: 'Oxygen';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    // line-height: 54px;
    display: flex;
    align-items: center;
    font-feature-settings: 'liga' off;
    color: #0039C5;
`
export const TitleCardCounter = styled.div`
    font-family: 'Oxygen';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    display: flex;
    font-feature-settings: 'liga' off;
    color: #6B6664;
    margin-block-start: 10px;
    height: 20px;
`

export const TitleContent = styled.h4`
    font-family: 'Oxygen';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: #0039C5;
    display: flex;
    align-items: center;
    font-feature-settings: 'liga' off;
`
export const CardPendingPolicies = styled(Card)`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    gap: 10px;
    height: 338px;
    box-shadow: 0px 6px 20px #EFF1F7 !important;
    border-radius: 8px !important;
    border-color: #EFF1F7 !important;
`
export const CardStatusControls = styled(Card)`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    height: 338px;
    box-shadow: 0px 6px 20px #EFF1F7 !important;
    border-radius: 8px !important;
    border-color: #EFF1F7 !important;
`

export const ItemPendingPolicy = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-weight: bold;
    &:hover {
        cursor: pointer;
    }
`

export const TopBar = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding: 2rem 0;
`
export const TopGraph = styled.div`
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
`
export const BlockList = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-around;
`

export const SingleBlock = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    flex-wrap: wrap;
    width: 30%;
    justify-content: space-around;
`

export const InfoBlock = styled(Card)`
    display: flex;
    flex-direction: column;
    width: 370px;
    height: 370px;
    box-shadow: 0px 6px 20px #EFF1F7 !important;
    border-radius: 16px !important;
    border: 1px solid #F2F2F2;
    padding: 16px;
    margin-bottom: 40px;
    gap: 5px;
`

export const BlockTitle = styled.h3`
    font-family: 'Oxygen';
    font-weight: 400;
    font-size: 24px;
    color: #0039C5;
    margin-block-start: 25px;
    margin-block-end: 9px;
    text-align: center;
`

export const BlockContent = styled.div`
    justify-content: center;
    text-align: center;
    align-items: center;
    margin-top: 20px;
    overflow: auto;
    padding-bottom: 10px;

`
export const BlockContentTable = styled.div`
    justify-content: center;
    text-align: center;
    align-items: center;
    margin-top: 20px;
    overflow: auto;
    padding-bottom: 10px;

`
export const TableLane = styled.div`
    display: flex;
    justify-content: space-around;;
    text-align: center;
    align-items: center;
`

export const TableTitle = styled.p`
    width: 7rem;
`

export const TableTxt = styled.p`

    text-overflow: ellipsis;

`
export const DomainSelector = styled.select`

  border: none;
  outline: none;

  height: 100%;
  background-color: transparent;
  color: #0039C5;
  font-family: 'Oxygen';
  font-weight: 400;
  font-size: 24px;
  text-align: center;
   &:hover {
        cursor: pointer;
        border: none;
        outline: none;
    }
    &:focus {
        border: none;
        outline: none;
    }
`

export const SideCards = styled.div`
    width: 70%;
    padding: 0 2.5rem;
`

export const MainContent = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;

`

export const SideLog = styled.div`
    width: 30%;
    display: flex;
    flex-direction: column;
    background-color: rgb(230 230 230);
    border-radius: 50px 0 0 50px;
    padding: 2rem;
    gap: 20px;
`

export const LogTitle = styled.h3`
    font-family: 'Oxygen';
    font-weight: 400;
    font-size: 24px;
    color: #0039C5;
    margin-block-start: 25px;
    margin-block-end: 9px;
    text-align: center;
`
export const LogContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
`

export const LogCard = styled(Card)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 24px;
    gap: 10px;
    min-height: 100px;
    width: 90%;
    box-shadow: 0px 6px 20px #EFF1F7 !important;
    border-radius: 12px !important;
    border-color: #EFF1F7 !important;
`

export const LogCardTitle = styled.h4`
    font-family: 'Oxygen';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    display: flex;
    align-items: center;
    font-feature-settings: 'liga' off;
`

export const LogScore = styled.div`
    min-width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Oxygen';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    color: #ffffff;
    background-color: ${c1};
    padding: 8px 10px;
    border-radius: 16px;
}
`

export const CardFooter = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Oxygen';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    text-align: center;
    text-decoration: none !important;
    color: #0039C5;
    &:hover {
        cursor: pointer;
        color: #0039C5;

}
`

export const StyleLink = styled(Link)`
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Oxygen';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    text-align: center;
    text-decoration: none !important;
    color: #0039C5;
    &:hover {
        cursor: pointer;
        color: #0039C5;

}
        `

export const LogArrow = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    right: 0;
`

export const CardContent = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    min-width: 100%;
    justify-content: space-between;
`